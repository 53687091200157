<template>
  <div class="page">
    <!-- Table查詢 -->
    <section class="table-search">
      <div class="search-L">
        <el-input
          v-model="tableParams.kw"
          placeholder="输入手机号搜索"
          @input="kwChange"
          clearable
          class="mr15"
        />
      </div>
      <div class="search-R">
        <el-button type="primary" @click="onExport()">导出数据</el-button>
      </div>
    </section>
    <!-- Table表格 -->
    <el-table
      :data="tableData"
      border
      stripe
      :header-cell-style="{
        background: '#FAFAFA',
        color: '#000000',
      }"
    >
      <el-table-column label="下单时间" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentDate || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="用户" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name || "-" }}</span>
          <span>&nbsp;丨&nbsp;</span>
          <span>{{ scope.row.mobile || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="商品">
        <template slot-scope="scope">
          <span>{{ scope.row.goodsInfo || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单位置">
        <template slot-scope="scope">
          <span>{{ scope.row.position || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="送达时间">
        <template slot-scope="scope">
          <span v-if="scope.row.reservationFlg == 0">立即送达</span>
          <span v-else
            >{{ scope.row.reservationStartTime }}-{{
              scope.row.reservationEndTime
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column label="订单备注">
        <template slot-scope="scope">
          <span>{{ scope.row.remark || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="支付方式">
        <template slot-scope="scope">
          <span>{{ scope.row.paymentInfo || "-" }}</span>
        </template>
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">
          <span>{{ scope.row.formStatusName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <MallRefund
            :row="scope.row"
            :businessType="tableParams.businessType"
            @reload="reload"
          ></MallRefund>

          <MallOperation
            :row="scope.row"
            :businessType="tableParams.businessType"
          ></MallOperation>

          <MallWorkOrder
            :row="scope.row"
            :businessType="tableParams.businessType"
            v-if="scope.row.formId"
          >
          </MallWorkOrder>

          <MallCancel
            :row="scope.row"
            :businessType="tableParams.businessType"
            v-if="scope.row.formStatus == 0"
            @reload="reload"

          >
          </MallCancel>
        </template>
      </el-table-column>
    </el-table>
    <!-- Table分页 -->
    <section class="table-footer">
      <el-pagination
        layout="total,sizes, prev, pager, next,jumper"
        background
        :total="total"
        :page-size="tableParams.size"
        @size-change="onSizeChange"
        :page-sizes="[10, 20, 50, 100]"
        :current-page.sync="currentPage"
        @current-change="onCurrentPage"
      >
      </el-pagination>
    </section>
  </div>
</template>

<script>
import { getOrderList, getListExport } from "@/api/mall/mall";
import MallRefund from "@/views/mall/mallOrder/MallRefund.vue";
import MallOperation from "@/views/mall/mallOrder/MallOperation.vue";
import MallWorkOrder from "@/views/mall/mallOrder/MallWorkOrder.vue";
import MallCancel from "@/views/mall/mallOrder/MallCancel";
export default {
  components: { MallRefund, MallOperation, MallWorkOrder, MallCancel },
  data() {
    return {
      // table表格
      currentPage: 1, // 当前页
      total: 0, // 总条数
      tableData: [], //表格数据
      tableParams: {
        page: 1,
        size: 10,
        kw: "", //查询关键字
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        businessType: 3,
      },
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getOrderList(); // 【请求】表格数据
  },
  methods: {
    // 【请求】表格数据
    getOrderList() {
      let data = this.tableParams;
      getOrderList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },

    // 【监听】表格模糊查询
    kwChange: _.debounce(function () {
      this.reload();
    }, 500),

    // 【监听】表格重载
    reload() {
      this.tableParams.page = 1;
      this.currentPage = 1;
      this.getOrderList();
    },

    // 【监听】表格分页点击
    onCurrentPage(page) {
      this.tableParams.page = page;
      this.getOrderList();
    },

    // 【监听】表格条数点击
    onSizeChange(size) {
      this.tableParams.size = size;
      this.reload();
    },

    // 【监听】编辑
    onEdit(row) {
      this.editShow = true;
      this.editTitle = "编辑";
      this.params = JSON.parse(JSON.stringify(row));
      this.$nextTick(() => {
        this.$refs["form-ref"].clearValidate();
      });
    },

    // 【监听】导出
    onExport() {
      let data = this.tableParams;
      getListExport(data, "派送订单");
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding: 0.15rem;
}

.table-search {
  margin-bottom: 0.15rem;
  display: flex;
  justify-content: space-between;
}

.table-footer {
  margin-top: 0.1rem;
  display: flex;
  justify-content: flex-end;
}
</style>