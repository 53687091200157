<template>
  <div class="mall-cancel">
    <el-button type="text" size="medium" @click.stop="goCancel()"
      >取消订单</el-button
    >
  </div>
</template>
<script>
import { goCancelOrder } from "@/api/mall/mall";

export default {
  props: ["row", "businessType"],
  data() {
    return {};
  },
  methods: {
    // 取消订单
    goCancelOrder() {
      let params = {
        businessType: this.businessType,
        orderId: this.row.orderId,
      };
      goCancelOrder(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$emit("reload");
        }
      });
    },

    goCancel() {
      this.$confirm("你确定要取消订单吗？", "取消订单", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.goCancelOrder();
      });
    },
  },
};
</script>
<style scoped>
.mall-cancel {
  display: inline-flex;
  margin-right: 0.1rem;
}
</style>