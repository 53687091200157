<template>
  <div class="mall-work-order">
    <el-button type="text" size="medium" @click.stop="onWorkOrder()"
      >查看工单</el-button
    >
    <!-- 列表弹框 -->
    <el-dialog
      :title="editTitle"
      :visible.sync="editShow"
      :close-on-click-modal="false"
      append-to-body
      width="560px"
    >
      <el-descriptions :column="2">
        <el-descriptions-item label="工单创建时间">{{
          dataInfo.addDt
        }}</el-descriptions-item>
        <el-descriptions-item label="工单状态">{{
          serviceStatusObj[dataInfo.serviceStatus]
        }}</el-descriptions-item>
        <el-descriptions-item label="送达时间">{{
          dataInfo.serviceEndTime || "-"
        }}</el-descriptions-item>
        <el-descriptions-item label="派送起止时间"
          >{{ dataInfo.serviceStartTime }}~{{
            dataInfo.serviceEndTime
          }}</el-descriptions-item
        >
        <el-descriptions-item label="取消时间" v-if="dataInfo.serviceStatus==6">{{
          dataInfo.updDate || "-"
        }}</el-descriptions-item>
      </el-descriptions>
    </el-dialog>
  </div>
</template>

<script>
import { getMallWorkOrder } from "@/api/mall/mall";
import { serviceStatusObj } from "@/db/objs";
export default {
  props: ["row", "businessType"],
  data() {
    return {
      serviceStatusObj,
      editShow: false, //弹框开关
      editTitle: "", //弹框标题
      dataInfo: "", //表格数据
    };
  },
  methods: {
    // 【请求】获取操作记录
    getMallWorkOrder() {
      let data = this.row;
      getMallWorkOrder(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.dataInfo = res.data;
        }
      });
    },

    // 【监听】打开操作记录
    onWorkOrder() {
      this.editShow = true;
      this.editTitle = "查看工单详情";
      this.getMallWorkOrder();
    },
  },
};
</script>

<style lang="scss" scoped>
.mall-work-order {
  display: inline-flex;
  margin-right: 0.1rem;
}
</style>